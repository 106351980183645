export const quiz = node => {
    const el = document.querySelectorAll(node);

    el.forEach(item => item.addEventListener('click', (e) => changeClass(e)));

    const changeClass = (e) => {
        e.preventDefault();
        e.currentTarget.classList.toggle('active');
    };
};
