import { post } from '../utils';
import { FB_API_VERSION } from '../constants';

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();

    return null;
};

const getUA = () => {
    return navigator.userAgent;
};

export const sendEvent = (accessToken, fbPixelId, eventName, eventTime, otherUserData = {}, customData = {}) => {
    const fbp = getCookie('_fbp');
    const fbc = getCookie('_fbc');
    const ua = getUA();
    const url = `https://graph.facebook.com/${FB_API_VERSION}/${fbPixelId}/events?access_token=${accessToken}`;
    const body = {
        data: [
            {
                event_name: eventName,
                event_time: eventTime,
                user_data: {
                    fbp,
                    fbc,
                    client_user_agent: ua,
                    ...otherUserData
                },
                ...customData
            }
        ]
    };
    return post(url, body);
};
