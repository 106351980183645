import AuthService from './authService';

const API_URL = process.env.API_URL;
const API_KEY = process.env.API_KEY;

const authService = new AuthService({
    apiKey: API_KEY,
    baseURL: API_URL
});

export default authService;
