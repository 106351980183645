import { closeModalBeforeLeave } from './closeModalBeforeLeave';

export const setScrollTop = () => {
    window.scrollTop = 0;
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
};

export const templateRenderer = templateId => {
    const page = document.querySelector('.main');
    const template = document.getElementById(templateId).content;
    let wrapper;
    const mount = () => {
        wrapper = document.createElement('div');
        wrapper.append(template.cloneNode(true));
        page.prepend(wrapper);
    };

    const unmount = () => {
        closeModalBeforeLeave();
        wrapper.remove();
        wrapper = null;
    };

    return { mount, unmount };
};

export const templateModal = templateId => {
    const template = document.getElementById(templateId).content;
    const wrapper = document.createElement('div');
    wrapper.appendChild(template);

    return wrapper;
};

export const defaultParam = {
    backdrop: false,
    keyboard: false,
    title: false,
    header: false,
    footer: false,
    buttons: false,
    headerClose: false,
    construct: false
};
