import BillingSDK from '@bendingspoons/mosaic-billing';
import authService from '../authService/auth';
const API_TM_KEY = process.env.API_TM_KEY;

const billingSDK = new BillingSDK({
    apiTMKey: API_TM_KEY,
    authInstance: authService
});

export default billingSDK;
