import Router from './createRouter';
import ROUTES from './constants';
import { templateRenderer, quiz, setScrollTop } from '../utils';

let timerId = null;

export default (path) => {
    const onboardingTemplate = templateRenderer('onboarding');
    const quizFirstTemplate = templateRenderer('quiz_1');
    const quizSecondTemplate = templateRenderer('quiz_2');
    const quizThirdTemplate = templateRenderer('quiz_3');
    const quizFourthTemplate = templateRenderer('quiz_4');
    const quizFivethTemplate = templateRenderer('quiz_5');

    return {
        [path]: {
            uses: _ => {
                setScrollTop();
                Router.updatePageLinks();
            },
            hooks: {
                before: (done) => {
                    onboardingTemplate.mount();
                    done();
                },
                leave: _ => {
                    onboardingTemplate.unmount();
                }
            }
        },
        [`${path}/1`]: {
            uses: (params, query) => {
                setScrollTop();
                if (query) {
                    document.querySelector('.white-card-nav__skip').setAttribute('href', '#quiz/2?ab');
                    document.querySelector('.white-card-nav__step').textContent = 'Step 2 of 4';
                    document.querySelector('.white-card-progress').classList.add('active2');
                }
                const el = document.querySelectorAll('.white-card-select .white-card-option');
                el.forEach(item => item.addEventListener('click', e => {
                    e.preventDefault();
                    const answer = e.currentTarget.children[1].innerText;
                    if (query) {
                        Router.navigate(`${path}/2?ab`);
                    } else {
                        Router.navigate(`${path}/2`);
                    }
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz step 1 - Option clicked',
                        {
                            eventCategory: 'quiz',
                            eventLabel: 'Quiz step 1 - Option clicked',
                            Answer: answer
                        });
                }));
            },
            hooks: {
                before: done => {
                    quizFirstTemplate.mount();
                    done();
                },
                leave: _ => {
                    quizFirstTemplate.unmount();
                }
            }
        },
        [`${path}/2`]: {
            uses: (params, query) => {
                setScrollTop();
                if (query) {
                    document.querySelector('.white-card-nav__skip').setAttribute('href', '#quiz/3?ab');
                    document.querySelector('.white-card-nav__step').textContent = 'Step 3 of 4';
                    document.querySelector('.white-card-progress').classList.add('active3');
                }
                const el = document.querySelectorAll('.white-card-select .white-card-option');
                el.forEach(item => item.addEventListener('click', e => {
                    e.preventDefault();
                    const answer = e.currentTarget.children[1].innerText;
                    if (query) {
                        Router.navigate(`${path}/3?ab`);
                    } else {
                        Router.navigate(`${path}/3`);
                    }
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz step 2 - Option clicked',
                        {
                            eventCategory: 'quiz',
                            eventLabel: 'Quiz step 2 - Option clicked',
                            Answer: answer
                        });
                }));
            },
            hooks: {
                before: done => {
                    quizSecondTemplate.mount();
                    done();
                },
                leave: _ => {
                    quizSecondTemplate.unmount();
                }
            }
        },
        [`${path}/3`]: {
            uses: (params, query) => {
                setScrollTop();
                if (query) {
                    document.querySelector('.white-card-nav__skip').setAttribute('href', '#quiz/5');
                    document.querySelector('.white-card-nav__step').textContent = 'Step 4 of 4';
                    document.querySelector('.white-card-progress').classList.add('active4');
                }
                const el = document.querySelectorAll('.white-card-select .white-card-option');
                el.forEach(item => item.addEventListener('click', e => {
                    e.preventDefault();
                    const answer = e.currentTarget.children[1].innerText;
                    if (query) {
                        Router.navigate(`${path}/5`);
                    } else {
                        Router.navigate(`${path}/4`);
                    }
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz step 3 - Option clicked',
                        {
                            eventCategory: 'quiz',
                            eventLabel: 'Quiz step 3 - Option clicked',
                            Answer: answer
                        });
                }));
            },
            hooks: {
                before: done => {
                    quizThirdTemplate.mount();
                    done();
                },
                leave: _ => {
                    quizThirdTemplate.unmount();
                }
            }
        },
        [`${path}/4`]: {
            uses: _ => {
                setScrollTop();
                Router.updatePageLinks();
                quiz('.white-card-select .white-card-option');
                const btn = document.querySelector('.white-card-btn a');
                const el = document.querySelectorAll('.white-card-select .white-card-option');
                const answer = [];
                let answers;
                el.forEach(item => item.addEventListener('click', e => {
                    e.preventDefault();
                    el.forEach((item) => {
                        if (item.classList.contains('active')) {
                            btn.classList.add('active');
                        }
                    });
                    answers = document.querySelectorAll('.white-card-option.active');
                }));
                btn.addEventListener('click', () => {
                    answers.forEach(item => {
                        answer.push(item.children[0].innerText);
                    });
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz step 4 - Option clicked',
                        {
                            eventCategory: 'quiz',
                            eventLabel: 'Quiz step 4 - Option clicked',
                            Answer: answer
                        });
                });
            },
            hooks: {
                before: done => {
                    quizFourthTemplate.mount();
                    done();
                },
                leave: _ => {
                    quizFourthTemplate.unmount();
                }
            }
        },
        [`${path}/5`]: {
            uses: _ => {
                Router.navigate(ROUTES.SIGNUP);
            },
            hooks: {
                before: done => {
                    setScrollTop();
                    quizFivethTemplate.mount();
                    timerId = setTimeout(done, 3000);
                },
                leave: _ => {
                    if (timerId) clearTimeout(timerId);
                    quizFivethTemplate.unmount();
                }
            }
        }
    };
};
