import Modal from 'modal-vanilla';
import jwtDecode from 'jwt-decode';
import {
    client as BraintreeClient,
    applePay,
    paypalCheckout,
    googlePayment
} from 'braintree-web';

import Router from './createRouter';
import ROUTES from './constants';
import {
    templateRenderer,
    clearPopup,
    showPopup,
    templateModal,
    defaultParam,
    setScrollTop,
    setNodeBenefit,
    setNodeLegal,
    getTimestampInSeconds
} from '../utils';
import authService from '../authService/auth';
import billingSDK from '../billing';
import { FB_ACCESS_TOKEN, FB_PIXEL_ID } from '../constants';
import { sendEvent } from '../services';

const STORE_NAME = 'blossom';
const CLIENT_AUTHORIZATION = process.env.CLIENT_AUTHORIZATION;
const MERCHANT_ID = process.env.MERCHANT_ID;
const MERCHANT_PAYPAL_ID = process.env.MERCHANT_PAYPAL_ID;
const ENVIRONMENT = process.env.ENVIRONMENT;

const popupFailedModal = templateModal('popup_payment_error');
const failedModal = () => {
    new Modal({
        content: popupFailedModal,
        ...defaultParam
    }).show();
};

const braintreeInit = ({ planPrice }, cb) => {
    const appleBtn = document.querySelector('.apple-btn');
    const appleOption = document.querySelector('.applepay');
    const gpayBox = document.querySelector('.gpay-box');
    const gpayOption = document.querySelector('.googlepay');
    let paymentsClient = null;
    // eslint-disable-next-line no-undef
    gPayLoaded.promise.then(() => {
        paymentsClient = new google.payments.api.PaymentsClient({ // eslint-disable-line no-undef
            environment: ENVIRONMENT
        });
    });
    // eslint-disable-next-line no-undef
    BraintreeClient.create({
        authorization: CLIENT_AUTHORIZATION
    }, (clientErr, clientInstance) => {
        if (clientErr) {
            console.error('Error creating client:', clientErr);
            return;
        };
        googlePayment.create({
            client: clientInstance,
            googlePayVersion: 2,
            googleMerchantId: MERCHANT_ID
        }, function(googlePaymentErr, googlePaymentInstance) {
            paymentsClient.isReadyToPay({
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
                existingPaymentMethodRequired: true // Optional
            }).then(function(response) {
                if (response.result) {
                    gpayOption.style.display = 'flex';
                    if (gpayOption.classList.contains('active')) {
                        gpayBox.style.display = 'block';
                    };
                    const button = document.querySelector('.gpay-btn');
                    button.addEventListener('click', function(event) {
                        event.preventDefault();
                        // eslint-disable-next-line no-undef
                        const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                            transactionInfo: {
                                currencyCode: 'USD',
                                totalPriceStatus: 'FINAL',
                                totalPrice: planPrice
                            }
                        });
                        const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
                        cardPaymentMethod.parameters.billingAddressRequired = true;
                        cardPaymentMethod.parameters.billingAddressParameters = {
                            format: 'FULL',
                            phoneNumberRequired: true
                        };

                        paymentsClient.loadPaymentData(paymentDataRequest).then(function(paymentData) {
                            googlePaymentInstance.parseResponse(paymentData, function(err, result) {
                                if (err) {
                                    clearPopup();
                                    console.log(err);
                                }
                                // eslint-disable-next-line standard/no-callback-literal
                                cb({ nonce: result.nonce }, 'GooglePay');
                            });
                        }).catch(function(err) {
                            clearPopup();
                            failedModal();
                            // eslint-disable-next-line no-undef
                            gtag('event', 'GPay payment failed');
                            console.log('error', err);
                        });
                    });
                }
            }).catch(function(err) {
                console.log('error', err);
            });
        });
        /* eslint-disable no-undef */
        paypalCheckout.create({
            client: clientInstance,
            merchantAccountId: MERCHANT_PAYPAL_ID
        }, (paypalCheckoutErr, paypalCheckoutInstance) => {
            /* eslint-disable no-useless-return */
            if (paypalCheckoutErr) {
                console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
                return;
            }
            /* eslint-disable space-before-function-paren */
            paypalCheckoutInstance.loadPayPalSDK({
                vault: true
            }, () => {
                paypal.Buttons({

                    style: {
                        layout: 'horizontal',
                        color: 'black',
                        height: 55,
                        label: 'paypal',
                        tagline: true
                    },

                    fundingSource: paypal.FUNDING.PAYPAL,

                    createBillingAgreement: () => {
                        showPopup();
                        return paypalCheckoutInstance.createPayment({
                            flow: 'vault',
                            displayName: STORE_NAME
                        });
                    },

                    onApprove: (data, actions) => { /* eslint-disable handle-callback-err */
                        return paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
                            /* eslint-disable standard/no-callback-literal */
                            cb({ nonce: payload.nonce }, 'PayPal');
                        });
                    },

                    onCancel: (data) => {
                        clearPopup();
                        gtag('event', 'PayPal payment canceled'); // eslint-disable-line no-undef
                        console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2));
                    },

                    onError: (err) => {
                        clearPopup();
                        failedModal();
                        gtag('event', 'PayPal payment failed'); // eslint-disable-line no-undef
                        console.error('PayPal error', err);
                    },

                    onClick: () => {
                        gtag('event', 'Subscribe with Paypal clicked'); // eslint-disable-line no-undef
                    }
                }).render('#paypal-button-selector').then(() => { // eslint-disable-next-line no-multi-spaces
                    const paypal =  document.querySelector('.paypal-box');
                    paypal.classList.add('active');
                    clearPopup();
                });
            });
        });
        /* eslint-disable no-undef */
        window.ApplePaySession &&
        ApplePaySession.supportsVersion(3) &&
        ApplePaySession.canMakePayments() &&
        applePay.create({
            client: clientInstance
        }, (applePayErr, applePayInstance) => {
            if (applePayErr) {
                clearPopup();
                console.error('Error creating applePayInstance:', applePayErr);
                return;
            };
            // eslint-disable-next-line no-undef
            ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier)
                .then(function(canMakePaymentsWithActiveCard) {
                    if (canMakePaymentsWithActiveCard) {
                        appleOption.style.display = 'flex';
                        appleBtn.addEventListener('click', () => {
                            applePayButtonClicked(applePayInstance, planPrice, cb);
                        });
                    }
                })
                .catch(error => {
                    clearPopup();
                    console.log('canMakePaymentsWithActiveCard:', error);
                });
        });
    });
};

const applePayButtonClicked = (applePayInstance, planPrice, cb) => {
    const paymentRequest = applePayInstance.createPaymentRequest({
        total: {
            label: STORE_NAME,
            amount: planPrice
        },
        requiredBillingContactFields: ['email']
    });

    const session = new ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = (event) => {
        applePayInstance.performValidation({
            validationURL: event.validationURL,
            displayName: STORE_NAME
        }, (err, merchantSession) => {
            if (err) {
                clearPopup();
                console.log('Apple Pay failed to load.', err);
                return;
            }
            session.completeMerchantValidation(merchantSession);
        });
    };

    session.onpaymentauthorized = (event) => {
        applePayInstance.tokenize({
            token: event.payment.token
        }, (tokenizeErr, payload) => {
            if (tokenizeErr) {
                clearPopup();
                failedModal();
                gtag('event', 'Apple Pay payment failed'); // eslint-disable-line no-undef
                console.log('Error tokenizing Apple Pay:', tokenizeErr);
                session.completePayment(ApplePaySession.STATUS_FAILURE); // eslint-disable-line no-undef
                return;
            }
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
            // eslint-disable-next-line standard/no-callback-literal
            cb({ nonce: payload.nonce }, 'ApplePay');
        });
    };

    session.begin();
};

const getPlan = (data, id) => {
    const { plans } = data;
    const arr = plans.find((plan) => {
        if (plan.id === id) {
            return plan;
        }
    });
    return arr;
};

const setActiveOption = (e) => {
    if (e) {
        const el = e.currentTarget;
        const list = document.querySelectorAll('.payment-option');
        for (let i = 0; i < list.length; i++) {
            list[i].classList.remove('active');
        }
        el.classList.add('active');
        const paymentBtns = document.querySelectorAll('.payment-btns div');
        for (let i = 0; i < paymentBtns.length; i++) {
            paymentBtns[i].style.display = 'none';
        }
        if (el.classList.contains('applepay')) {
            document.querySelector('.apple-box').style.display = 'block';
            document.querySelector('.apple-btn').style.display = 'flex';
        } else if (el.classList.contains('googlepay')) {
            document.querySelector('.gpay-box').style.display = 'block';
            document.querySelector('.gpay-btn').style.display = 'flex';
        } else if (el.classList.contains('paypal')) {
            document.querySelector('.paypal-box.active').style.display = 'block';
            document.querySelector('#paypal-button-selector').style.display = 'block';
            document.querySelector('#paypal-button-selector div').style.display = 'inline-block';
        } else if (el.classList.contains('creditcard')) {
            document.querySelector('.credit').style.display = 'flex';
        }
    }
};

export default path => {
    const priceListTemplate = templateRenderer('payment_options');
    return {
        [`${path}/:id`]: {
            uses: (params) => {
                setScrollTop();
                Router.updatePageLinks();
                showPopup();
                billingSDK.getPaymentPlans()
                    .then(response => {
                        if (response) {
                            const { data } = response;
                            const paymentOption = document.querySelectorAll('.payment-option');
                            paymentOption.forEach(item => item.addEventListener('click', (e) => setActiveOption(e)));
                            const { trialDuration, price, id } = getPlan(data, params.id);
                            setNodeBenefit(trialDuration, price);
                            setNodeLegal(document.querySelector('.payment-text p:first-child'), { trialDuration, price, id });
                            document.querySelector('.form-subheader').innerHTML = `${trialDuration ? 'Your credit card will not be charged during your ' + trialDuration + '-day trial' : ''}`;
                            const btn = document.querySelector('.form-submit');
                            btn.setAttribute('href', '#billing/' + params.id);
                            braintreeInit({
                                planPrice: price
                            }, ({ nonce }, metod) => {
                                billingSDK.paymentCheckout({
                                    nonce,
                                    planId: id
                                }).then((response) => {
                                    const { error } = response;
                                    clearPopup();
                                    if (error) {
                                        failedModal();
                                        console.log('pay failed');
                                    } else {
                                        const user = authService.getUser(jwtDecode);
                                        // eslint-disable-next-line camelcase
                                        const { plan_id, subscription_id } = response.data;

                                        // eslint-disable-next-line no-undef
                                        gtag('event', 'checkout_completed',
                                            {
                                                eventCategory: 'purchases',
                                                eventLabel: plan_id,
                                                SubscriptionID: subscription_id,
                                                UserID: user.id,
                                                PaymentMethod: metod,
                                                Source: 'Regular'
                                            });
                                        const eventTime = getTimestampInSeconds();
                                        sendEvent(FB_ACCESS_TOKEN, FB_PIXEL_ID, 'Purchase', eventTime, { subscription_id }, { currency: 'USD', value: price });
                                        Router.navigate(ROUTES.CONGRATS);
                                    }
                                });
                            });
                        } else {
                            clearPopup();
                            Router.navigate(ROUTES.LOGIN);
                        }
                    });
            },
            hooks: {
                before: done => {
                    priceListTemplate.mount();
                    done();
                },
                leave: () => {
                    priceListTemplate.unmount();
                }
            }
        }
    };
};
