import Router from './createRouter';
import jwtDecode from 'jwt-decode';
import Modal from 'modal-vanilla';
import { addDays, format, getTime, isPast, parseISO } from 'date-fns';
import billingSDK from '../billing';

import authService from '../authService/auth';
import {
    templateModal,
    templateRenderer,
    showPopup,
    clearPopup,
    defaultParam,
    decryptData,
    encryptData,
    setScrollTop
} from '../utils';
import ROUTES from './constants';

const setFreeUser = () => {
    document.querySelectorAll('.free').forEach(item => item.classList.add('show'));
};

const dropNodes = () => {
    document.querySelectorAll('.pmob').forEach(item => item.classList.remove('show'));
    document.querySelectorAll('.paid').forEach(item => item.classList.remove('show'));
    document.querySelectorAll('.pweb').forEach(item => item.classList.remove('show'));
    document.querySelectorAll('.puweb').forEach(item => item.classList.remove('show'));
    const parentNode = document.querySelector('.form-info-plan');
    while (parentNode.firstChild) parentNode.removeChild(parentNode.firstChild);
};

const setPaidUser = subscription => {
    const { data, productId } = subscription[0];
    const isMobile = data.paymentProcessor !== 'braintree';
    document.querySelector('.form-info-unsubscribe button').setAttribute('data-id', data.transactionId);
    document.querySelectorAll('.pmob').forEach(item => isMobile && item.classList.add('show'));
    document.querySelectorAll('.paid').forEach(item => item.classList.add('show'));
    const parentNode = document.querySelector('.form-info-plan');
    const div = document.createElement('div');
    const isAnnual = productId.indexOf('y_') !== -1;
    const isActive = data.cancelReason === undefined;
    document.querySelectorAll('.pweb').forEach(item => !isMobile && !isActive && item.classList.add('show'));
    document.querySelectorAll('.puweb').forEach(item => !isMobile && isActive && item.classList.add('show'));
    const namePlan = isAnnual ? 'Annual Plan' : 'Monthly plan';
    const pricePlan = isAnnual ? '$29.99' : '$3.99';
    const endDate = format(data.expirationTime, 'dd MMMM, yyyy');
    const initDate = format(data.buyTime, 'dd MMMM, yyyy');
    const startPaidDate = format(data.isTrial ? addDays(data.buyTime, 3) : addDays(data.expirationTime, 1), 'dd MMMM, yyyy');
    const statusPlan = isActive ? `Activated: ${initDate}` : `You'll lose access: ${endDate}`;
    const notePlan = isActive ? `Payment will be debited: ${startPaidDate}` : 'Please, renew your subscription to enjoy Blossom Premium.';
    div.innerHTML = `<div class="form-info-plan-top">
                         <div class="form-info-plan-top__plan">${namePlan}</div>
                         <div class="form-info-plan-top__status">${statusPlan}</div>
                     </div>
                     <div class="form-info-plan-bot">
                          <div class="form-info-plan-bot__price ${!isActive && 'hidden'}">${pricePlan}</div>
                          <div class="form-info-plan-bot__note">${notePlan}</div>
                     </div>`;
    parentNode.prepend(div);
};

const popupDeleteModal = templateModal('popup_delete_user');
const deleteModal = () => {
    new Modal({
        content: popupDeleteModal,
        ...defaultParam
    }).show().once('dismiss', (modal, ev) => {
        if (ev.target.classList.contains('popup-box-btn__no')) {
            showPopup();
            authService.deleteUserAccount(() => {
                gtag('event', 'Profile - Delete account confirmed'); // eslint-disable-line no-undef
                setTimeout(() => {
                    clearPopup();
                    Router.navigate(ROUTES.INDEX);
                }, 300);
            });
        }
    });
};

const billing = () => {
    authService.getBraintreeSubscriptions().then(response => {
        const { data } = response;
        billingSDK.verify(encryptData, decryptData)
            .then(
                (response) => {
                    const subscriptionsList = response.filter(item => item.data.subscriptionStatus === 'active');
                    const mobSubs = subscriptionsList.filter(item => item.data?.paymentProcessor !== 'braintree').sort((a, b) => a.data?.buyTime - b.data?.buyTime).slice(0, 1);
                    let webSubs = subscriptionsList.filter(item => item.data?.paymentProcessor === 'braintree').sort((a, b) => a.data?.buyTime - b.data?.buyTime).slice(0, 1);
                    if (!webSubs.length && data) {
                        const expirationDate = getTime(parseISO(data[0].expirationDate));
                        webSubs = [{
                            data: {
                                paymentProcessor: 'braintree',
                                isTrial: data[0].price > 10,
                                transactionId: data[0].id,
                                buyTime: getTime(parseISO(data[0].createdAt.date)),
                                expirationTime: expirationDate
                            },
                            productId: data[0].price < 10 ? 'blossom_web_main_03-99_01m_00dt' : 'blossom_web_main_29-99_01y_03dt'
                        }];
                        if (data[0].status === 'Canceled') {
                            webSubs[0].data.cancelReason = 'system';
                        }
                        if (isPast(expirationDate) && data[0].status === 'Canceled' && !mobSubs.length) {
                            setFreeUser();
                            clearPopup();
                            return;
                        }
                    }
                    const subscriptions = [...webSubs, ...mobSubs];
                    setPaidUser(subscriptions);
                    clearPopup();
                }
            )
            .catch(() => {
                setFreeUser();
                clearPopup();
            });
    });
};

const popupUnsubscribeModal = templateModal('popup_unsubscribe');
const unsubscribeModal = e => {
    const id = e.currentTarget.getAttribute('data-id');
    new Modal({
        content: popupUnsubscribeModal,
        ...defaultParam
    }).show().once('dismiss', (modal, ev) => {
        if (ev.target.classList.contains('popup-box-btn__no')) {
            showPopup();
            authService.deleteSubscription(id, () => {
                gtag('event', 'Profile - Subscription canceled'); // eslint-disable-line no-undef
                setTimeout(() => {
                    dropNodes();
                    billing();
                    clearPopup();
                }, 300);
            });
        }
    });
};

export default path => {
    const profileTemplate = templateRenderer('profile');
    return {
        [path]: {
            uses: _ => {
                setScrollTop();
                Router.updatePageLinks();
                const deleteUser = document.querySelector('.delete');
                deleteUser.addEventListener('click', deleteModal);
                const unsubscribeUser = document.querySelector('.form-info-unsubscribe button');
                unsubscribeUser.addEventListener('click', (e) => unsubscribeModal(e));
                showPopup();
                billingSDK.getPaymentPlans().then(
                    response => {
                        if (response.message) {
                            clearPopup();
                            Router.navigate(ROUTES.LOGIN);
                        } else {
                            const name = document.querySelector('.form-info__user');
                            name.innerHTML = authService.getUserName(jwtDecode);
                            billing();
                        }
                    });
            },
            hooks: {
                before: done => {
                    profileTemplate.mount();
                    done();
                },
                leave: _ => {
                    profileTemplate.unmount();
                }
            }
        }
    };
};
