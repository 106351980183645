let checkboxValue = false;

export const renderConsent = response => {
    const checkbox = document.querySelector('input[type=checkbox]');
    const concent = document.querySelector('.concent-box');
    const nonGDPR = document.querySelector('.consent-other');
    if (response.data.data_collection_consent === 'required') {
        concent.style.display = 'block';
        nonGDPR.style.display = 'none';
        checkbox.setAttribute('data-consent', 'denied');
        checkbox.checked = checkboxValue;
    } else {
        concent.style.display = 'none';
        nonGDPR.style.display = 'block';
        checkbox.setAttribute('data-consent', 'not_determined');
    }
    checkbox.addEventListener('change', (e) => {
        e.target.checked ? checkboxValue = true : checkboxValue = false;
    });
};

export const getConsent = () => {
    const checkbox = document.querySelector('input[type=checkbox]');
    let consent = checkbox.dataset.consent;
    if (consent !== 'not_determined') {
        if (checkbox.checked) {
            consent = 'authorized';
            // eslint-disable-next-line no-undef
            gtag('event', 'Check box checked');
        } else {
            consent = 'denied';
        }
    }
    return consent;
};
