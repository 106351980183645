import 'smart-app-banner/dist/smart-app-banner.css';
import authService from './authService/auth';
import {
    ROUTES,
    QuizRoute,
    StartRoute,
    StartNewRoute,
    IndexRoute,
    SignupRoute,
    PricesRoute,
    PaymentRoute,
    BillingRoute,
    CongratsRoute,
    LoginRoute,
    ProfileRoute,
    ForgotRoute,
    ResetRoute,
    Router
} from './router';
// import faker from 'faker';

const IMAGES_PATH = '/assets/images';

// Preload image assets preventing
// image flickering on page transitions
(_ => {
    const preloadImgs = [];
    const images = [];

    for (var i = 0; i < preloadImgs.length; i++) {
        images[i] = new Image(); // eslint-disable-line no-undef
        images[i].src = `${IMAGES_PATH}/${preloadImgs[i]}`;
    }
})();

/*
Keep in mind that order of routes is matter
*/
Router.on({
    ...QuizRoute(ROUTES.QUIZ),
    ...StartRoute(ROUTES.START),
    ...StartNewRoute(ROUTES.STARTNEW),
    ...SignupRoute(ROUTES.SIGNUP),
    ...PricesRoute(ROUTES.PRICES),
    ...PaymentRoute(ROUTES.PAYMENT),
    ...BillingRoute(ROUTES.BILLING),
    ...CongratsRoute(ROUTES.CONGRATS),
    ...LoginRoute(ROUTES.LOGIN),
    ...ProfileRoute(ROUTES.PROFILE),
    ...ForgotRoute(ROUTES.FORGOT),
    ...ResetRoute(ROUTES.RESET),
    ...IndexRoute(ROUTES.INDEX)
});

Router.notFound(_ => {
    Router.navigate(ROUTES.INDEX);
});

// ENTRY POINT
const resolveApp = () => {
    authService.init(() => {
        Router.resolve();
    });
};

document.onload = resolveApp();
