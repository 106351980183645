import { clearPopup, defaultParam, showPopup, templateModal } from '../utils';
import * as SmartBanner from 'smart-app-banner/dist/smart-app-banner.js';
import billingSDK from '../billing';
import authService from '../authService/auth';
import jwtDecode from 'jwt-decode';
import Modal from 'modal-vanilla';

let pageH = 0;

const getCoords = (elem) => {
    const box = elem.getBoundingClientRect();
    return {
        top: box.top
    };
};

window.addEventListener('scroll', () => {
    setAnim();
    pageH = document.documentElement.clientHeight;
});

const headerMenu = document.querySelector('.header-menu');
const signOutButton = document.querySelector('.sign-out-button');
const upgradePlanButton = document.querySelector('.account-menu a:nth-of-type(2)');

const setAnim = () => {
    const navb = document.querySelector('.get-started');
    const topBorder = document.querySelector('.start-journey .get-app');
    const botBorder = document.querySelector('.start-now .start-now__stores');
    const botMob = document.querySelector('.leaf-mob .get-app');
    if (((getCoords(topBorder).top + 56) <= 0 && getCoords(botBorder).top > pageH) || ((getCoords(topBorder).top + 56) <= 0 && getCoords(botMob).top > pageH)) {
        navb.classList.add('visible');
    } else {
        navb.classList.remove('visible');
    }
};

const popupSignOut = templateModal('popup_signout');
const signOutModal = e => {
    e.preventDefault();
    new Modal({
        content: popupSignOut,
        ...defaultParam
    }).show().once('dismiss', (modal, ev) => {
        if (ev.target.classList.contains('popup-box-btn__no')) {
            showPopup();
            setTimeout(() => {
                clearPopup();
                authService.clear();
                headerMenu.classList.remove('logged', 'active');
                document.querySelector('.header').classList.remove('active');
                gtag('event', 'Account sign out'); // eslint-disable-line no-undef
            }, 300);
        }
    });
};

export default path => ({
    [`${path}`]: {
        uses: () => {
            gtag('event', 'Home page opened'); // eslint-disable-line no-undef
            new SmartBanner({ // eslint-disable-line no-new
                daysHidden: 15,
                daysReminder: 90,
                appStoreLanguage: 'us',
                title: 'Blossom - Plant Identifier',
                author: 'Conceptiv Apps LLC',
                button: 'View'
            });
            headerMenu.classList.add('loading');
            billingSDK.getPaymentProfile().then(
                response => {
                    headerMenu.classList.remove('loading');
                    const { data, message } = response; // eslint-disable-line camelcase
                    if (message) {
                        headerMenu.classList.remove('logged');
                    } else {
                        headerMenu.classList.add('logged');
                        document.querySelector('.account-user span').innerHTML = authService.getUserName(jwtDecode);
                        if (data.payment_customer_id) { // eslint-disable-line camelcase
                            upgradePlanButton.style.display = 'none';
                        } else {
                            upgradePlanButton.style.display = 'flex';
                        }
                    }
                }
            );
            signOutButton.addEventListener('click', signOutModal);
        },
        hooks: {
            before: done => {
                const page = document.querySelector('.home-main');
                page.removeAttribute('hidden');
                const start = document.querySelector('.get-started');
                start.classList.add('hidden');
                done();
            },
            leave: _ => {
                const page = document.querySelector('.home-main');
                page.setAttribute('hidden', true);
                const start = document.querySelector('.get-started');
                start.classList.remove('hidden');
                document.querySelector('.header-menu').classList.remove('logged', 'active', 'loading');
                document.querySelector('.header').classList.remove('active');
            }
        }
    }
});
