import Router from './createRouter';
import { templateRenderer, Slider, startFloat, endFloat, setScrollTop } from '../utils';

export default (path) => {
    const startTemplate = templateRenderer('start');

    return {
        [path]: {
            uses: _ => {
                setScrollTop();
                Router.updatePageLinks();
                const firstVariant = document.querySelector('.first-variant');
                const feature = document.querySelector('.feature');
                const observer = new MutationObserver(function(mutations) { // eslint-disable-line no-undef
                    mutations.forEach(function(mutation) {
                        if (document.body.hasAttribute('data-test')) {
                            feature.style.cssText = 'display: block';
                            firstVariant.style.cssText = 'display: none';
                        } else {
                            feature.style.cssText = 'display: none';
                            firstVariant.style.cssText = 'display: block';
                        }
                    });
                });
                const config = { attributes: true, childList: false, characterData: false };
                observer.observe(document.body, config);
                if (document.body.hasAttribute('data-test')) {
                    feature.style.cssText = 'display: block';
                    firstVariant.style.cssText = 'display: none';
                } else {
                    feature.style.cssText = 'display: none';
                    firstVariant.style.cssText = 'display: block';
                }
            },
            hooks: {
                before: (done) => {
                    startTemplate.mount();
                    startFloat();
                    const wrapper = document.querySelector('.slider-wrapper');
                    const pagination = document.querySelector('.slider-pagination');
                    const prevButton = document.querySelector('.slider-prev');
                    const nexButton = document.querySelector('.slider-next');
                    const slider = new Slider(wrapper, pagination, prevButton, nexButton);
                    slider.initSlider();
                    done();
                },
                leave: _ => {
                    endFloat();
                    startTemplate.unmount();
                }
            }
        }
    };
};
