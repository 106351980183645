export default {
    INDEX: '',
    QUIZ: 'quiz',
    START: 'start',
    STARTNEW: 'start-new',
    RESULT: 'result',
    PRICES: 'prices',
    PAYMENT: 'payment',
    BILLING: 'billing',
    SIGNUP: 'signup',
    CONGRATS: 'congrats',
    LOGIN: 'login',
    PROFILE: 'profile',
    FORGOT: 'forgot',
    RESET: 'reset'
};
