const getCoords = (elem) => {
    const box = elem.getBoundingClientRect();
    return {
        top: box.top,
        bot: box.bottom
    };
};

const setAnim = () => {
    const nextNode = document.querySelector('.stuck-content');
    const floatNode = document.querySelector('.floating-box');
    const nNode = getCoords(nextNode).bot;
    const scrollHeight = Math.max(
        document.body.clientHeight,
        window.innerHeight
    );
    if (scrollHeight > nNode) {
        floatNode.classList.remove('float');
    } else {
        floatNode.classList.add('float');
    }
};

export const startFloat = () => {
    window.addEventListener('scroll', setAnim);
};

export const endFloat = () => {
    window.removeEventListener('scroll', setAnim);
};
