import Router from './createRouter';
import { templateRenderer, startFloat, endFloat, setScrollTop } from '../utils';

export default path => {
    const startTemplate = templateRenderer('start-new');

    return {
        [path]: {
            uses: () => {
                setScrollTop();
                Router.updatePageLinks();
            },
            hooks: {
                before: done => {
                    startTemplate.mount();
                    startFloat();
                    done();
                },
                leave: () => {
                    endFloat();
                    startTemplate.unmount();
                }
            }
        }
    };
};
