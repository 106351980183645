export const validateForm = (source) => {
    const form = document.querySelectorAll('form input[type=text], form input[type=password]');
    let valid = true;
    form.forEach(item => {
        item.classList.remove('invalid');
        if (item.value.length === 0) {
            valid = false;
        } else {
            const type = item.getAttribute('type') === 'password' ? 'password' : 'email';
            // eslint-disable-next-line no-undef
            gtag('event', `${source} ${type} entered`);
        };
    });
    const errorBox = document.querySelector('.form-error');
    errorBox.style.display = 'none';
    const submit = document.querySelector('.form-submit');
    submit.setAttribute('disabled', true);
    if (valid) submit.removeAttribute('disabled');
};

export const submitValidate = (cb, source) => {
    const form = document.querySelectorAll('form input[type=text], form input[type=password]');
    let valid = true;
    form.forEach(item => {
        item.classList.remove('invalid');
        if (!item.validity.valid) {
            item.classList.add('invalid');
            valid = false;
        }
    });
    const errorBox = document.querySelector('.form-error');
    if (valid) {
        errorBox.style.display = 'none';
        cb();
    } else {
        // eslint-disable-next-line no-undef
        if (source) gtag('event', `${source} failed`);
        errorBox.style.display = 'block';
    }
};
