import serialize from 'form-serialize';
import Modal from 'modal-vanilla';

import { defaultParam, setScrollTop, submitValidate, templateModal, templateRenderer, validateForm } from '../utils';
import ROUTES from './constants';
import Router from './createRouter';
import authService from '../authService/auth';

const popupDone = templateModal('popup_done');
const doneModal = (email) => {
    new Modal({
        content: popupDone,
        ...defaultParam
    })
        .show()
        .once('shown', () => {
            const text = document.querySelector('.popup-box__text');
            text.innerHTML = `We've sent an email with instructions to <br /> <strong>${email}</strong>`;
        })
        .once('dismiss', () => {
            setTimeout(() => {
                Router.navigate(ROUTES.LOGIN);
            }, 300);
        });
};

export default path => {
    const formForgotTemplate = templateRenderer('form_forgot');

    return {
        [path]: {
            uses: _ => {
                setScrollTop();
                const inputs = document.querySelectorAll('#forgot_form input');
                const form = document.getElementById('forgot_form');
                let email = null;
                const submitCallback = () => {
                    const data = serialize(form, { hash: true });
                    email = data.email;
                    authService.forgotPassword({ email: email }, () => doneModal(email));
                };
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    submitValidate(submitCallback);
                });
                inputs.forEach(item => item.addEventListener('input', () => validateForm('Forgot password')));
            },
            hooks: {
                before: done => {
                    formForgotTemplate.mount();
                    done();
                },
                leave: _ => {
                    formForgotTemplate.unmount();
                }
            }
        }
    };
};
