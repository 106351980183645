import serialize from 'form-serialize';
import { OAuthIdentity, AuthContext } from '@bendingspoons/mosaic-auth';
import jwtDecode from 'jwt-decode';
import Modal from 'modal-vanilla';

import Router from './createRouter';
import ROUTES from './constants';
import {
    templateModal,
    templateRenderer,
    clearPopup,
    showPopup,
    togglePassword,
    validateForm,
    renderConsent,
    getConsent,
    submitValidate,
    defaultParam,
    setScrollTop,
    encryptData,
    decryptData
} from '../utils';
import authService from '../authService/auth';
import billingSDK from '../billing';

const registerExist = templateModal('popup_exist');
const existModal = () => {
    new Modal({
        content: registerExist,
        ...defaultParam
    }).show().once('dismiss', () => {
        setTimeout(() => {
            Router.navigate(ROUTES.LOGIN);
        }, 300);
    });
};

const billing = () => {
    billingSDK.verify(encryptData, decryptData)
        .then(
            (data) => {
                const lastSub = data.reduce((prev, current, i) => {
                    if (current.data.buyTime > prev && current.data.subscriptionStatus === 'active') {
                        return i;
                    }
                    return prev;
                }, 0);
                const subscription = data[lastSub];
                if (subscription) {
                    Router.navigate(ROUTES.PROFILE);
                } else {
                    Router.navigate(ROUTES.PRICES);
                }
                clearPopup();
            }
        )
        .catch(() => {
            // eslint-disable-next-line no-undef
            gtag('event', 'Account created successfully');
            Router.navigate(ROUTES.PRICES);
            clearPopup();
        });
};

const socialSignIn = (data) => {
    showPopup();
    const oAuthIdentity = new OAuthIdentity();
    oAuthIdentity.setAccessToken(data.access_token);
    oAuthIdentity.setIDToken(data.id_token);
    const context = new AuthContext();
    const contextValue = getConsent();
    context.setConsent(contextValue);
    authService.signInWithOAuth(data.type, oAuthIdentity, context)
        .then((response) => {
            if (response.message) {
                clearPopup();
            } else {
                billing();
                if (response.isNewUser) {
                    // eslint-disable-next-line no-undef
                    gtag('event', 'signup',
                        {
                            eventCategory: 'purchases',
                            eventLabel: 'signup'
                        });
                } else {
                    gtag('event', 'Login successful'); // eslint-disable-line no-undef
                }
            }
        });
};

const appleLogin = () => {
    AppleID.auth.signIn(); // eslint-disable-line no-undef
};

const appleOn = (data) => {
    const tkn = data.detail.authorization.id_token;
    socialSignIn({ type: 'apple', id_token: tkn });
};

const appleErr = (error) => {
    console.log(error);
};

export default (path) => {
    const template = templateRenderer('signup_form');
    const createAccountTemplate = templateRenderer('signup_create');
    return {
        [path]: {
            uses: (params, query) => {
                setScrollTop();
                Router.updatePageLinks();
                showPopup();
                authService.getRegion().then((response) => {
                    renderConsent(response);
                    clearPopup();
                });
                const buttonElement = document.getElementById('sign-in-with-apple-button');
                buttonElement.style.display = 'none';
                // eslint-disable-next-line no-undef
                appleLoaded.promise.then(() => {
                    buttonElement.style.display = 'flex';
                    buttonElement.addEventListener('click', appleLogin);
                });
                document.addEventListener('AppleIDSignInOnSuccess', appleOn);
                document.addEventListener('AppleIDSignInOnFailure', appleErr);
                const googleBtnClick = () => {
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Log in with Google clicked');
                };
                // eslint-disable-next-line no-undef
                gpLoaded.promise.then(() => {
                    // eslint-disable-next-line no-undef
                    google.accounts.id.initialize({
                        client_id: '114060081361-6hht4vk98t5t11je2ufqa13rgjcegtb4.apps.googleusercontent.com',
                        callback: response => {
                            socialSignIn({ type: 'google', id_token: response.credential });
                        }
                    });
                    // eslint-disable-next-line no-undef
                    google.accounts.id.renderButton(
                        document.getElementById('customBtn'),
                        { theme: 'outline', size: 'large', logo_alignment: 'center', locale: 'en-US', width: 160, text: 'signin', shape: 'circle', click_listener: googleBtnClick }
                    );
                });
                const fb = document.querySelector('.form-btn_fb');
                fb.style.display = 'none';
                // eslint-disable-next-line no-undef
                fbLoaded.promise.then(() => {
                    // eslint-disable-next-line no-undef
                    FB.getLoginStatus((response) => {
                        if (response.status === 'connected') {
                            // eslint-disable-next-line no-undef
                            FB.logout();
                        }
                    });
                    fb.style.display = 'flex';
                    fb.addEventListener('click', loginFb);
                });
                function loginFb() {
                    // eslint-disable-next-line no-undef
                    FB.login((response) => {
                        if (response.status === 'connected') {
                            socialSignIn({ type: 'facebook', id_token: response.authResponse.accessToken });
                        } else {
                            console.log('error');
                        }
                    }, { scope: 'email' });
                }
            },
            hooks: {
                before: done => {
                    createAccountTemplate.mount();
                    done();
                },
                leave: _ => {
                    document.removeEventListener('AppleIDSignInOnSuccess', appleOn);
                    document.removeEventListener('AppleIDSignInOnFailure', appleErr);
                    createAccountTemplate.unmount();
                }
            }
        },
        [`${path}/register`]: {
            uses: () => {
                setScrollTop();
                Router.updatePageLinks();
                const form = document.getElementById('register_form');
                const inputs = document.querySelectorAll('#register_form input');
                showPopup();
                authService.getRegion().then((response) => {
                    renderConsent(response);
                    clearPopup();
                });
                inputs.forEach(item => item.addEventListener('input', () => validateForm('Sign up')));
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    submitValidate(submitCallback, 'Sign up');
                });
                const submitCallback = event => {
                    showPopup();
                    const data = serialize(form, {
                        hash: true
                    });
                    const { email, password } = data;
                    authService.signUpWithEmailPassword({ email, password })
                        .then(
                            (response) => {
                                if (response.message) {
                                    // eslint-disable-next-line no-undef
                                    gtag('event', 'Account already exists error');
                                    clearPopup();
                                    existModal();
                                } else {
                                    authService.signInWithEmailPassword({
                                        username: email,
                                        password
                                    }).then(
                                        () => {
                                            // eslint-disable-next-line no-undef
                                            gtag('event', 'Account created successfully');
                                            const user = authService.getUser(jwtDecode);
                                            // eslint-disable-next-line no-undef
                                            gtag('event', 'signup',
                                                {
                                                    eventCategory: 'purchases',
                                                    eventLabel: 'signup',
                                                    UserID: user.id
                                                });
                                            const consent = getConsent();
                                            authService.sendEmail({
                                                email,
                                                data_collection_consent: consent
                                            }).then(() => {
                                                clearPopup();
                                                Router.navigate(ROUTES.PRICES);
                                            }).catch(() => {
                                                clearPopup();
                                                existModal();
                                                console.error('Consent collection was not sent');
                                            });
                                        }
                                    );
                                }
                            }
                        );
                };
                inputs.forEach(item => {
                    item.addEventListener('input', () => validateForm('Sign up'));
                });
                const passwordEye = document.querySelector('.form-eye');
                passwordEye.addEventListener('click', e => togglePassword(e));
            },
            hooks: {
                before: (done, params) => {
                    template.mount();
                    done();
                },
                leave: _ => {
                    template.unmount();
                }
            }
        }
    };
};
