import serialize from 'form-serialize';
import { OAuthIdentity, AuthContext } from '@bendingspoons/mosaic-auth';
import Modal from 'modal-vanilla';

import authService from '../authService/auth';
import Router from './createRouter';
import ROUTES from './constants';
import {
    templateModal,
    templateRenderer,
    clearPopup,
    showPopup,
    togglePassword,
    validateForm,
    renderConsent,
    getConsent,
    submitValidate,
    defaultParam,
    setScrollTop
} from '../utils';

const popupFailedModal = templateModal('popup_failed');
const failedModal = () => {
    new Modal({
        content: popupFailedModal,
        ...defaultParam
    }).show();
};

const socialLogin = (data) => {
    showPopup();
    const oAuthIdentity = new OAuthIdentity();
    oAuthIdentity.setAccessToken(data.access_token);
    oAuthIdentity.setIDToken(data.id_token);
    const context = new AuthContext();
    const contextValue = getConsent();
    context.setConsent(contextValue);
    authService.signInWithOAuth(data.type, oAuthIdentity, context)
        .then(
            (response) => {
                clearPopup();
                if (response.message) {
                    failedModal();
                } else {
                    if (response.isNewUser) {
                        // eslint-disable-next-line no-undef
                        gtag('event', 'signup',
                            {
                                eventCategory: 'purchases',
                                eventLabel: 'signup'
                            });
                    } else {
                        gtag('event', 'Login successful'); // eslint-disable-line no-undef
                    }
                    Router.navigate(ROUTES.PROFILE);
                }
            }
        );
};

const appleLogin = () => {
    AppleID.auth.signIn(); // eslint-disable-line no-undef
};

const appleOn = (data) => {
    const tkn = data.detail.authorization.id_token;
    socialLogin({ type: 'apple', id_token: tkn });
};

const appleErr = (error) => {
    console.log(error);
};

export default path => {
    const formLoginTemplate = templateRenderer('login_template');

    return {
        [path]: {
            uses: () => {
                setScrollTop();
                Router.updatePageLinks();
                const form = document.getElementById('login_form');
                authService.getRegion().then((response) => {
                    renderConsent(response);
                    clearPopup();
                });
                const submitCallback = () => {
                    showPopup();
                    const data = serialize(form, {
                        hash: true
                    });
                    const { email, password } = data;
                    authService.signInWithEmailPassword({
                        username: email,
                        password
                    })
                        .then((response) => {
                            if (response.message) {
                                clearPopup();
                                gtag('event', 'Login failed'); // eslint-disable-line no-undef
                                failedModal();
                            } else {
                                const consent = getConsent();
                                authService.sendEmail({
                                    email,
                                    data_collection_consent: consent
                                }).then(() => {
                                    clearPopup();
                                    gtag('event', 'Login successful'); // eslint-disable-line no-undef
                                    Router.navigate(ROUTES.PROFILE);
                                }).catch(() => {
                                    clearPopup();
                                    gtag('event', 'Login failed'); // eslint-disable-line no-undef
                                    failedModal();
                                    console.error('Consent collection was not sent');
                                });
                            }
                        });
                };
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    submitValidate(submitCallback);
                });
                const inputs = document.querySelectorAll('#login_form input');
                inputs.forEach(item => {
                    item.addEventListener('input',
                        () => validateForm('Log in')
                    );
                });
                const passwordEye = document.querySelector('.form-eye');
                passwordEye.addEventListener('click', e => togglePassword(e));
                const googleBtnClick = () => {
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Log in with Google clicked');
                };
                // eslint-disable-next-line no-undef
                gpLoaded.promise.then(() => {
                    // eslint-disable-next-line no-undef
                    google.accounts.id.initialize({
                        client_id: '114060081361-6hht4vk98t5t11je2ufqa13rgjcegtb4.apps.googleusercontent.com',
                        callback: response => {
                            socialLogin({ type: 'google', id_token: response.credential });
                        }
                    });
                    // eslint-disable-next-line no-undef
                    google.accounts.id.renderButton(
                        document.getElementById('customBtn'),
                        { theme: 'outline', size: 'large', logo_alignment: 'center', locale: 'en-US', width: 160, text: 'signin', shape: 'circle', click_listener: googleBtnClick }
                    );
                });
                const fb = document.querySelector('.form-btn_fb');
                fb.style.display = 'none';
                // eslint-disable-next-line no-undef
                fbLoaded.promise.then(() => {
                    // eslint-disable-next-line no-undef
                    FB.getLoginStatus((response) => {
                        if (response.status === 'connected') {
                            // eslint-disable-next-line no-undef
                            FB.logout();
                        }
                    });
                    fb.style.display = 'flex';
                    fb.addEventListener('click', loginFb);
                });
                function loginFb() {
                    // eslint-disable-next-line no-undef
                    FB.login((response) => {
                        if (response.status === 'connected') {
                            socialLogin({ type: 'facebook', id_token: response.authResponse.accessToken });
                        } else {
                            console.log('error');
                        }
                    }, { scope: 'email' });
                }
                const buttonElement = document.getElementById('sign-in-with-apple-button');
                buttonElement.style.display = 'none';
                // eslint-disable-next-line no-undef
                appleLoaded.promise.then(() => {
                    buttonElement.style.display = 'flex';
                    buttonElement.addEventListener('click', appleLogin);
                });
                document.addEventListener('AppleIDSignInOnSuccess', appleOn);
                document.addEventListener('AppleIDSignInOnFailure', appleErr);
            },
            hooks: {
                before: done => {
                    formLoginTemplate.mount();
                    done();
                },
                leave: () => {
                    formLoginTemplate.unmount();
                }
            }
        }
    };
};
