export class FeatureSlider {
    static get ATTRIBUTE() {
        return 'number';
    }

    constructor(interval = 6000, wrapper, controls, left, right) {
        this.timer = null;
        this.slide = 1;
        this.interval = interval;
        this.wrapper = wrapper;
        this.controls = controls;
        this.maxSlide = controls.length;
        this.left = left;
        this.right = right;
    }

    stop() {
        clearInterval(this.timer);
        this.timer = null;
    }

    remove() {
        this.wrapper.classList.remove(`slide${this.slide}`);
    }

    start() {
        if (this.timer !== null) return;
        this.timer = setInterval(() => {
            this.remove();
            this.slide++;
            if (this.slide > this.maxSlide) this.slide = 1;
            this.setSlide();
        }, this.interval);
    }

    setSlide() {
        this.wrapper.classList.add(`slide${this.slide}`);
    }

    load() {
        this.controls.forEach(item => item.addEventListener('click', e => {
            this.stop();
            this.remove();
            this.slide = e.currentTarget.getAttribute(FeatureSlider.ATTRIBUTE);
            this.setSlide();
            this.start();
        }));
        this.start();
        this.right.addEventListener('click', () => {
            this.stop();
            this.remove();
            this.slide++;
            if (this.slide > this.maxSlide) this.slide = 1;
            this.setSlide();
            this.start();
        });
        this.left.addEventListener('click', () => {
            this.stop();
            this.remove();
            this.slide--;
            if (this.slide <= 0) this.slide = this.maxSlide;
            this.setSlide();
            this.start();
        });
    }
}
