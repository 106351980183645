import Router from './createRouter';
import queryString from 'query-string';
import Modal from 'modal-vanilla';
import serialize from 'form-serialize';

import ROUTES from './constants';
import {
    defaultParam,
    setScrollTop,
    submitValidate,
    templateModal,
    templateRenderer,
    togglePassword,
    validateForm
} from '../utils';
import authService from '../authService/auth';

const popupReset = templateModal('popup_reset_ok');
const resetModal = () => {
    new Modal({
        content: popupReset,
        ...defaultParam
    }).show().once('dismiss', () => {
        setTimeout(() => {
            Router.navigate(ROUTES.LOGIN);
        }, 300);
    });
};

export default path => {
    const resetTemplate = templateRenderer('form_reset');

    return {
        [path]: {
            uses: (params, query) => {
                gtag('event', 'Forgot password page opened'); // eslint-disable-line no-undef
                setScrollTop();
                const { token } = queryString.parse(query);
                if (!token) {
                    Router.navigate(ROUTES.INDEX);
                }
                const inputs = document.querySelectorAll('#form_res input');
                const form = document.getElementById('form_res');

                const submitCallback = () => {
                    const data = serialize(form, {
                        hash: true
                    });
                    if (token) {
                        authService.resetPassword({
                            password: data.password,
                            token
                        }, () => resetModal());
                    }
                };
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    submitValidate(submitCallback);
                });
                inputs.forEach(item => item.addEventListener('input', () => validateForm('Reset')));
                const passwordEye = document.querySelectorAll('.form-eye');
                passwordEye.forEach(item => item.addEventListener('click', e => togglePassword(e)));
            },
            hooks: {
                before: done => {
                    resetTemplate.mount();
                    done();
                },
                leave: _ => {
                    resetTemplate.unmount();
                }
            }
        }
    };
};
