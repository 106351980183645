import CryptoJS from 'crypto-js';

const CRYPT_KEY = process.env.CRYPT_KEY;

export const encryptData = (data) => {
    const key = CRYPT_KEY;
    const k = CryptoJS.enc.Utf8.parse(key);
    const cypher = CryptoJS.AES.encrypt(data, k, { mode: CryptoJS.mode.ECB });
    return CryptoJS.enc.Base64.stringify(cypher.ciphertext);
};

export const decryptData = (data) => {
    const key = CRYPT_KEY;
    const k = CryptoJS.enc.Utf8.parse(key);
    const cypher = CryptoJS.AES.decrypt(data, k, { mode: CryptoJS.mode.ECB });
    return JSON.parse(cypher.toString(CryptoJS.enc.Utf8));
};
