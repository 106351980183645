import {
    client as BraintreeClient,
    hostedFields
} from 'braintree-web';
import Router from './createRouter';
import ROUTES from './constants';
import {
    templateModal,
    templateRenderer,
    clearPopup,
    showPopup,
    activateContinue,
    defaultParam,
    setScrollTop,
    setNodeBenefit,
    setNodeLegal,
    getTimestampInSeconds
} from '../utils';
import jwtDecode from 'jwt-decode';
import authService from '../authService/auth';
import billingSDK from '../billing';
import Modal from 'modal-vanilla';
import { sendEvent } from '../services';
import { FB_ACCESS_TOKEN, FB_PIXEL_ID } from '../constants';

const CLIENT_AUTHORIZATION = process.env.CLIENT_AUTHORIZATION;

const popupFailedModal = templateModal('popup_card_error');
const failedModal = () => {
    new Modal({
        content: popupFailedModal,
        ...defaultParam
    }).show();
};

const popupCvv = templateModal('popup_cvv');
const cvvModal = () => {
    new Modal({
        content: popupCvv,
        ...defaultParam
    }).show();
};

export default path => {
    const billingFormTemplate = templateRenderer('payment_by_card');

    return {
        [`${path}/:id`]: {
            uses: params => {
                Router.updatePageLinks();
                setScrollTop();
                const { id } = params;
                showPopup();
                let curPrice;
                billingSDK.getPaymentPlans()
                    .then(response => {
                        if (response) {
                            const { data } = response;
                            const { plans } = data;
                            const { price, trialDuration } = plans.find(plan => plan.id === id);
                            curPrice = price;
                            setNodeBenefit(trialDuration, price);
                            setNodeLegal(document.querySelector('.payment-text p:first-child'), { trialDuration, price, id });
                            document.querySelector('.form-subheader').innerHTML = `${trialDuration ? 'Your credit card will not be charged during your ' + trialDuration + '-day trial' : ''}`;
                        } else {
                            clearPopup();
                            Router.navigate(ROUTES.LOGIN);
                        }
                    });
                // eslint-disable-next-line handle-callback-err
                const createHostedFields = (err, client) => {
                    hostedFields.create({
                        client: client,
                        id: 'cardForm',
                        styles: {
                            input: {
                                'font-size': '22px',
                                color: '#2C6455',
                                'font-family': 'Montserrat, sans-serif',
                                'font-weight': '500',
                                'font-variant-caps': 'all-petite-caps'
                            },

                            '.invalid': {
                                color: '#FF7171 !important'
                            }
                        },
                        fields: {
                            number: {
                                selector: '#paymanet_form_card_number',
                                placeholder: 'Card Number'
                            },
                            cardholderName: {
                                selector: '#payment_form_cardholder_name',
                                placeholder: 'Name on card'
                            },
                            expirationMonth: {
                                selector: '#payment_form_expiration_month',
                                placeholder: 'MM'
                            },
                            expirationYear: {
                                selector: '#payment_form_expiration_year',
                                placeholder: 'YY'
                            },
                            cvv: {
                                selector: '#payment_form_cvv',
                                placeholder: 'CVV',
                                type: 'password'
                            }
                        }
                    },
                    (err, hostedFields) => { // eslint-disable-line handle-callback-err
                        const submitBtn = document.getElementById('submit-btn');
                        submitBtn.addEventListener('click', submitHandler.bind(null, hostedFields));
                        activateContinue(hostedFields);
                        clearPopup();
                    });
                };
                const submitHandler = (hostedFields, event) => {
                    event.preventDefault();
                    showPopup();
                    const submitBtn = document.getElementById('submit-btn');
                    hostedFields.tokenize((error, payload) => {
                        if (error) {
                            submitBtn.removeAttribute('disabled');
                            clearPopup();
                            failedModal();
                            gtag('event', 'Card payment error'); // eslint-disable-line no-undef
                            gtag('event', 'Card credits failed'); // eslint-disable-line no-undef
                        } else {
                            const form = document.getElementById('cardForm');
                            form.payment_method_nonce.value = payload.nonce;
                            billingSDK.paymentCheckout({
                                nonce: payload.nonce,
                                planId: id
                            }).then((response) => {
                                const { error } = response;
                                if (error) {
                                    gtag('event', 'Card payment error'); // eslint-disable-line no-undef
                                    gtag('event', 'Card credits failed'); // eslint-disable-line no-undef
                                    clearPopup();
                                    failedModal();
                                } else {
                                    const user = authService.getUser(jwtDecode);
                                    // eslint-disable-next-line camelcase
                                    const { plan_id, subscription_id } = response.data;
                                    // eslint-disable-next-line no-undef
                                    gtag('event', 'checkout_completed',
                                        {
                                            eventCategory: 'purchases',
                                            eventLabel: plan_id,
                                            SubscriptionID: subscription_id,
                                            UserID: user.id,
                                            PaymentMethod: 'Hosted Fields',
                                            Source: 'Regular'
                                        });
                                    clearPopup();
                                    const eventTime = getTimestampInSeconds();
                                    sendEvent(FB_ACCESS_TOKEN, FB_PIXEL_ID, 'Purchase', eventTime, { subscription_id }, { currency: 'USD', value: curPrice });
                                    Router.navigate(ROUTES.CONGRATS);
                                }
                            });
                        }
                    });
                };
                if (params) {
                    BraintreeClient.create(
                        {
                            authorization: CLIENT_AUTHORIZATION
                        },
                        createHostedFields
                    );
                }
                document.querySelector('.cvv-after').addEventListener('click', cvvModal);
            },
            hooks: {
                before: (done) => {
                    billingFormTemplate.mount();
                    done();
                },
                leave: _ => {
                    billingFormTemplate.unmount();
                }
            }
        },
        [`${path}`]: {
            uses: _ => {
                setScrollTop();
                Router.navigate(ROUTES.RESULT);
            }
        }
    };
};
