export const addDaysPlusFormat = (count, options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
}) => {
    const today = new Date();
    const countDueDay = today.setDate(today.getDate() + count);
    const dueDay = new Date(countDueDay);
    return (dueDay.toLocaleDateString('en-US', options));
};
