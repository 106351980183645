export const post = (url = '', data = {}) => {
    // eslint-disable-next-line no-undef
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(error => {
            console.log(error);
        });
};
