export const setNodeBenefit = (trialDuration, price) => {
    document.querySelector('.container__text div b').style.cssText = `${!trialDuration && 'display: none;'}`;
    const featuresTrial = `<li>You will have a free ${trialDuration} days trial</li><li>Total due during trial $0.00</li><li>Reminder before your trial ends</li>`;
    const featuresNoTrial = `<li>Total due today $${price}</li><li>Reminder before your plan ends</li>`;
    const benefits = document.querySelector('.subscription-benefits');
    if (benefits) benefits.innerHTML = `${trialDuration ? featuresTrial : featuresNoTrial}<li>Cancel anytime</li>`;
};

export const setNodeLegal = (node, data) => {
    const { price, trialDuration, id } = data;
    const legalText = `${trialDuration} days free, then $${price}/${id.indexOf('y_') !== -1 ? 'year' : 'month'}. `;
    node.innerHTML = `${trialDuration ? legalText : ''}Cancel in settings at least 24h before end of ${trialDuration ? 'trial or' : ''} subscription to avoid being charged, see <a class="payment-links" href="https://conceptivapps.com/eula.html#d4 " target="_blank" rel="nofollow">Cancellation Policy</a>. See <a class="payment-links" href="https://conceptivapps.com/terms.html" target="_blank" rel="nofollow">Terms</a> and <a class="payment-links" href="https://conceptivapps.com/privacy_policy.html" target="_blank" rel="nofollow">Privacy policy</a>.`;
};
